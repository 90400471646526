import React, { useState, useEffect } from "react";
import image from "../../../Assets/Images/Bliv.club logo.png";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { GiHamburgerMenu } from "react-icons/gi";
import { RiCloseLine } from "react-icons/ri";
import "./navbar.css";
import { useAccount } from "wagmi";
import axios from "axios";
import { isAdminStore } from "../../../store";

const Navbar = () => {
  const [active, setActive] = useState(false);
  const { address, isConnected } = useAccount();
  const { isAdmin, setIsAdmin } = isAdminStore();

  useEffect(() => {
    const isAdmin = async () => {
      if (isConnected && address) {
        try {
          // console.log("add", String(address));
          const res = await axios.get(
            "https://api.bliv.club/v1/whitelistedAccounts/isAdmin",
            {params: {address}}
          );
          if (res.data?.message === true) {
            setIsAdmin(true);
          } else {
            setIsAdmin(false);
          }
          // console.log("res", res);
        } catch (error) {
          console.log("error", error);
        }
      }
    };

    isAdmin();
  }, [address]);

  // console.log("isAdmin", isAdmin);

  const showMenu = () => {
    setActive(!active);
  };

  return (
    <div className="bg-blivBg-1000 header">
      <div className="menu-icon">
        <GiHamburgerMenu className="menu" onClick={showMenu} />
      </div>

      <nav className={active ? "slider active" : "slider bg-blivBg-1000"}>
        <ul>
          <div className="closed">
            <RiCloseLine
              className="close"
              style={{ color: "white" }}
              onClick={showMenu}
            />
          </div>

          <li>
            <div>
              <img src={image} alt="Logo" className="h-10" />
            </div>
          </li>
          <li>
            <div>
              <ConnectButton.Custom>
                {({
                  account,
                  chain,
                  openAccountModal,
                  openChainModal,
                  openConnectModal,
                  authenticationStatus,
                  mounted,
                }) => {
                  const ready = mounted && authenticationStatus !== "loading";
                  const connected =
                    ready &&
                    account &&
                    chain &&
                    (!authenticationStatus ||
                      authenticationStatus === "authenticated");

                  return (
                    <div>
                      {(() => {
                        if (!connected) {
                          return (
                            <button
                              onClick={openConnectModal}
                              type="button"
                              className="bg-gradient-to-br from-[#7F34DE] to-[#0DA492] px-4 py-[0.2rem] ml-4 font-normal rounded color-white text-white"
                            >
                              Connect Wallet
                            </button>
                          );
                        }

                        return (
                          <div style={{ display: "flex", gap: 12 }}>
                            <button
                              onClick={openAccountModal}
                              type="button"
                              className="bg-gradient-to-br from-[#7F34DE] to-[#0DA492] px-4 py-[0.2rem] ml-4 font-normal rounded color-white text-white"
                            >
                              {account.displayName}
                            </button>
                          </div>
                        );
                      })()}
                    </div>
                  );
                }}
              </ConnectButton.Custom>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
