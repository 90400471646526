import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <div className='px-12 fixed bottom-0 right-0 left-0 footer bg-blivBg-1000'>
      <span className="text-blivTxt-1000 text-[14px] font-poppins">
        © 2023 Bliv.club. All rights reserved
      </span>

      <div className='flex justify-between items-center py-4 gap-x-7'>
        <a id="linkedin-icon" href="https://www.linkedin.com/company/bliv-club/" target="blank">
          <img
            className="image_on"
            src="footerIcons/linkedin-icon-1.png"
            alt=""
            style={{height: '35px', width: '35px'}}
          />
          <img
            className="image_off"
            src="footerIcons/linkedin-icon-2.svg"
            alt=""
            style={{height: '35px', width: '35px'}}
          />
        </a>
        <a id="medium-icon" href="https://medium.com/@bliv_club" target="blank">
          <img
            className="image_on"
            src="footerIcons/medium-icon-1.png"
            alt=""
            style={{height: '35px', width: '35px'}}
          />
          <img
            className="image_off"
            src="footerIcons/medium-icon-2.svg"
            alt=""
            style={{height: '35px', width: '35px'}}
          />
        </a>
        <a id="discord-icon" href="https://discord.gg/TzUHyqc5sw" target="blank">
          <img
            className="image_on"
            src="footerIcons/discord-icon-1.png"
            alt=""
            style={{height: '35px', width: '35px'}}
          />
          <img
            className="image_off"
            src="footerIcons/discord-icon-2.svg"
            alt=""
            style={{height: '35px', width: '35px'}}
          />
        </a>
        <a id="telegram-icon" href="https://t.me/+ox68Uvbl3FFjOWE1" target="blank">
          <img
            className="image_on"
            src="footerIcons/telegram-icon-1.png"
            alt=""
            style={{height: '35px', width: '35px'}}
          />
          <img
            className="image_off"
            src="footerIcons/telegram-icon-2.svg"
            alt=""
            style={{height: '35px', width: '35px'}}
          />
        </a>
        <a id="twitter-icon" href="https://twitter.com/BlivClub" target="blank">
          <img
            className="image_on"
            src="footerIcons/twitter-icon-1.png"
            alt=""
            style={{height: '35px', width: '35px'}}
          />
          <img
            className="image_off"
            src="footerIcons/twitter-icon-2.svg"
            alt=""
            style={{height: '35px', width: '35px'}}
          />
        </a>
      </div>
    </div>
  );
};

export default Footer;
