import Navbar from "./Components/Input/Navbar/Navbar.jsx";
import Input from "./Components/Input/Input.jsx";
import Footer from "./Components/Footer/Footer.jsx";
import "@rainbow-me/rainbowkit/styles.css";
import React from "react";
import {
  getDefaultWallets,
  RainbowKitProvider,
} from "@rainbow-me/rainbowkit";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import { polygon, polygonMumbai } from "wagmi/chains";

function App() {
  const { chains, provider } = configureChains(
    [polygonMumbai],
    [publicProvider()]
  );

  const { connectors } = getDefaultWallets({
    appName: "Bliv.club",
    chains,
  });

  const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider,
  });

  return (
    <>
      <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider chains={chains} >
          <Navbar />
          <Input />
          <Footer />
        </RainbowKitProvider>
      </WagmiConfig>
    </>
  );
}

export default App;
