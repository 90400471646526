import React, { useState } from "react";
import "./input.css";
import axios from "axios";
import { useAccount, useSignMessage } from "wagmi";
import { isAdminStore } from "../../store.js";
import { Snackbar } from "@mui/material";
import { RxCross2 } from "react-icons/rx";

const Input = () => {
  const { isAdmin } = isAdminStore();
  const [input, setInput] = useState();
  const { isConnected } = useAccount();
  const { signMessageAsync } = useSignMessage();
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [alertmessage, setAlertMessage ] = useState('');
  // const toast = useToast();

  // const getNonceRes = await primaryServerApi.post(`/jwt/getnonce`, {
  //   user: address
  // });

  // const signature = await signMessageAsync({ message: getNonceRes?.data?.nonce });
  // const [addresses, setAddresses] = useState();

  // const whiteListFunc = async () => {
  //   for (let i = 0; i < addresses?.length; i++) {
  //     const getNonceRes = await axios.post(`https://api.bliv.club/v1/primaryServer/jwt/getnonce`, {
  //       user: addresses[i]
  //     });
  //   }
  // };

  // console.log('data2', addresses);

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  const handleCloseError = () => {
    setOpenError(false);
  };

  const addWhiteListAccount = async () => {
    if (isConnected) {
      const getNonceRes = await axios.get(
        `https://api.bliv.club/v1/whitelistedAccounts/getNonce`
      );

      let signature = await signMessageAsync({
        message: getNonceRes?.data?.nonce,
      });

      const address = input;

      try {
        const res = await axios.post(
          "https://api.bliv.club/v1/whitelistedAccounts/addWhiltelistedAccount",
          {
            address: address,
            signature: signature,
          },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        if (res?.status === 200) {
          setAlertMessage('Whitelisted successfully!');
          setOpenSuccess(true);
        } else {
          setAlertMessage('');
          setOpenError(true);
        }
      } catch (error) {
        setAlertMessage('');
        setOpenError(true);
      }
    }
  };

  const removeWhiteListAccount = async () => {
    if (isConnected) {
      const getNonceRes = await axios.get(
        `https://api.bliv.club/v1/whitelistedAccounts/getNonce`
      );

      const signature = await signMessageAsync({
        message: getNonceRes?.data?.nonce,
      });

      const address = input;
      try {
        const res = await axios.delete(
          "https://api.bliv.club/v1/whitelistedAccounts/removeWhitelistedAccount",
          {
            data: {
              address: address,
              signature: signature,
            },
          },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        if (res?.status === 200) {
          setAlertMessage('Blacklisted successfully!');
          setOpenSuccess(true);
        } else {
          setAlertMessage('');
          setOpenError(true);
        }
      } catch (error) {
        setAlertMessage('');
        setOpenError(true);
      }
    }
  };

  return (
    <div className="input-container">
      {isAdmin ? (
        <div className="input">
          <Snackbar
            open={openSuccess}
            autoHideDuration={5000}
            onClose={handleCloseSuccess}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <div className="relative top-[50px] left-[25px] ">
              <div className="bg-[#65ACF0] text-16px font-medium text-white border-[#65ACF0] h-[50px] p-[10px] rounded-l-[10px] flex justify-center items-center gap-[10px]  ">
                <span>{alertmessage}</span>
                <RxCross2
                  className="relative bottom-[14px] left-[5px] cursor-pointer "
                  onClick={() => setOpenSuccess(false)}
                />
              </div>
            </div>
          </Snackbar>

          <Snackbar
            open={openError}
            autoHideDuration={5000}
            onClose={handleCloseError}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <div className="relative top-[50px] left-[25px] ">
              <div className="bg-[#DB434D] text-16px font-medium text-white border-[#DB434D] h-[50px] p-[10px] rounded-l-[10px] flex justify-center items-center gap-[10px]  ">
                <span>THERE IS SOME ERROR. PLEASE TRY AGAIN LATER!</span>
                <RxCross2
                  className="relative bottom-[14px] left-[5px] cursor-pointer "
                  onClick={() => setOpenError(false)}
                />
              </div>
            </div>
          </Snackbar>
          <input
            type="text"
            className="inputField outline-0 text-blivTxt-1000 mr-[15px] rounded-[5px]"
            placeholder=" Enter your wallet address"
            onChange={(event) => setInput(event.target.value)}
          />
          <div className="flex justify-center items-center gap-[15px] ">
            <button
              onClick={() => {
                addWhiteListAccount();
              }}
              className="bg-gradient-to-br from-[#7F34DE] to-[#0DA492] px-5 color-white text-white rounded-[5px] font-semibold text-sm btn"
            >
              Whitelist
            </button>
            <button
              onClick={() => {
                removeWhiteListAccount();
              }}
              className="bg-gradient-to-br from-[#7F34DE] to-[#0DA492] px-5 color-white text-white rounded-[5px] font-semibold text-sm btn"
            >
              Blacklist
            </button>
            {/* <button onClick={()=> {setAddresses(input && input.split(" "))
        whiteListFunc()}} className='bg-gradient-to-br from-[#7F34DE] to-[#0DA492] px-5 color-white text-white rounded-r-sm font-semibold text-sm btn' >Whitelist</button> */}
          </div>
        </div>
      ) : (
        <div className="border-blivBg-1000 bg-blivBg-1000 w-[600px] h-[100px] flex justify-center items-center text-[16px] font-medium text-white ">
          <div>You are not an ADMIN</div>
        </div>
      )}
    </div>
  );
};

export default Input;
